import account from './account.json';
import analytics from './analytics.json';
import base from './base.json';
import beta from './beta.json';
import billingAlerts from './billingAlerts.json';
import bulkMessaging from './bulk-messaging.json';
import bundle from './bundle.json';
import bundleUpgrade from './bundleUpgrade.json';
import businessInfo from './business-info.json';
import callLogs from './call-logs.json';
import chat from './chat.json';
import contacts from './contacts.json';
import dataSources from './dataSources.json';
import digitalFormsSignupPage from './digitalFormsSignupPage.json';
import entity from './entity.json';
import example from './example.json';
import fax from './fax.json';
import featurePromotion from './featurePromotion.json';
import forms from './forms.json';
import freeTrials from './freeTrials.json';
import home from './home.json';
import insurance from './insurance.json';
import insuranceDetails from './insuranceDetails.json';
import insuranceVerification from './insuranceVerification.json';
import integrations from './integrations.json';
import locations from './locations.json';
import marketing from './marketing.json';
import messages from './messages.json';
import onboarding from './onboarding.json';
import onlineSchedulingSignUpPage from './onlineSchedulingSignUpPage.json';
import organizationBilling from './organizationBilling.json';
import payments from './payments.json';
import phone from './phone.json';
import phoneProviderInformationForm from './phoneProviderInformationForm.json';
import phoneSync from './phoneSync.json';
import porting from './porting.json';
import portingLoa from './portingLoa.json';
import productOnboardingTasks from './productOnboardingTasks.json';
import resetPassword from './resetPassword.json';
import reviews from './reviews.json';
import salesDev from './salesDev.json';
import schedulePulse from './schedule-pulse.json';
import scheduleQuickFill from './schedule-quick-fill.json';
import schedule from './schedule.json';
import setupMobileNumber from './setupMobileNumber.json';
import signIn from './SignIn.json';
import softphone from './softphone.json';
import subscribe from './subscribe.json';
import subscription from './subscription.json';
import textConnectSignupPage from './textConnectSignupPage.json';
import units from './units.json';
import userCreate from './userCreate.json';
import weavePay from './weave-pay.json';

export default {
  account,
  analytics,
  base,
  beta,
  billingAlerts,
  'bulk-messaging': bulkMessaging,
  bundle,
  bundleUpgrade,
  businessInfo,
  callLogs,
  chat,
  contacts,
  dataSources,
  digitalFormsSignupPage,
  entity,
  example,
  fax,
  featurePromotion,
  forms,
  home,
  insurance,
  insuranceVerification,
  locations,
  marketing,
  messages,
  onboarding,
  payments,
  phone,
  phoneSync,
  resetPassword,
  reviews,
  salesDev,
  schedule,
  setupMobileNumber,
  signIn,
  softphone,
  subscribe,
  subscription,
  units,
  weavePay,
  portingLoa,
  freeTrials,
  onlineSchedulingSignUpPage,
  productOnboardingTasks,
  porting,
  integrations,
  phoneProviderInformationForm,
  insuranceDetails,
  organizationBilling,
  textConnectSignupPage,
  userCreate,
  'schedule-quick-fill': scheduleQuickFill,
  'schedule-pulse': schedulePulse,
};
