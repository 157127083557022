import React, { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  FloatingPortal,
  FloatingNode,
  FloatingOverlay,
  FloatingFocusManager,
  FloatingContext,
  ReferenceType,
} from '@floating-ui/react';
import { AnimatePresence, motion, type MotionProps } from 'motion/react';
import { useThemeValues } from '../../hooks';
import { styles } from '../../styles';
import { DrawerHeader, DrawerContent } from './atoms';
import { drawerSizes, backdropVariants, createDrawerVariants, sideStyles, drawerBackdropId } from './constants';
import { DrawerContext } from './context';
import type { DrawerSide, DrawerSize } from './types';

type DrawerBaseProps = React.HTMLAttributes<HTMLDivElement> & {
  /** IDs for a11y (dialog label and description) */
  labelId?: string;
  descriptionId?: string;
  /** Callback to close the drawer. */
  close?: () => void;
  /** Which side the drawer should appear from. */
  side?: DrawerSide;
  /** One of the predefined sizes. */
  size?: DrawerSize;
  /** Controls whether the drawer is open or closed. */
  isOpen?: boolean;
  /**
   * If true, the drawer stays mounted in the DOM at all times.
   * We then toggle between `hidden` and `visible` states,
   * rather than unmounting/remounting.
   */
  keepMounted?: boolean;
  /** Provided by useFloating for focus management. */
  context: FloatingContext<ReferenceType>;
  children: React.ReactNode;
  zIndex?: number;
} & MotionProps;

type DrawerPanelProps = React.HTMLAttributes<HTMLDivElement> & {
  labelId?: string;
  descriptionId?: string;
  side: DrawerSide;
  size: DrawerSize;
  isOpen?: boolean;
  keepMounted?: boolean;
  children: React.ReactNode;
  zIndex?: number;
} & MotionProps;

const DrawerPanel = forwardRef<HTMLDivElement, DrawerPanelProps>(
  (
    {
      labelId,
      descriptionId,
      isOpen,
      side,
      style,
      size,
      keepMounted,
      variants,
      children,
      zIndex: customZIndex,
      ...rest
    },
    ref
  ) => {
    const { colors, spacing, zIndex: zIndexDefault, heightOffset } = useThemeValues();

    const dimensionStyles = useMemo(
      () => ({
        width: side === 'left' || side === 'right' ? drawerSizes[size] : 'auto',
        height: side === 'top' || side === 'bottom' ? drawerSizes[size] : '100%',
        maxWidth: side === 'left' || side === 'right' ? drawerSizes[size] : '100%',
      }),
      [side, size]
    );

    const selectedSideStyles = useMemo(() => sideStyles(heightOffset)[side], [heightOffset, side]);

    return (
      <motion.div
        ref={ref}
        key='drawer-panel'
        initial='hidden'
        animate={isOpen ? 'visible' : 'hidden'}
        exit='exit'
        variants={variants}
        role='dialog'
        aria-modal='true'
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
        css={[
          css`
            background: ${colors.white};
            position: absolute;
            z-index: ${customZIndex ?? zIndexDefault.modals};
            display: flex;
            flex-direction: column;
            padding: ${spacing(4)};
            gap: ${spacing(2)};
          `,
          selectedSideStyles,
          dimensionStyles,
          keepMounted &&
            css`
              position: fixed;
            `,
        ]}
        {...rest}
      >
        {children}
      </motion.div>
    );
  }
);

DrawerPanel.displayName = 'DrawerPanel';

const MotionOverlay = motion(FloatingOverlay);

const Overlay = ({ zIndex }: { zIndex?: number }) => (
  <MotionOverlay
    id={drawerBackdropId}
    lockScroll
    initial='hidden'
    animate='visible'
    exit='exit'
    variants={backdropVariants}
    css={(theme) => [
      css`
        z-index: ${zIndex ?? theme.zIndex.modals};
        background-color: rgba(0, 33, 82, 0.3);
        position: fixed;
        top: 0;
        height: 100%;
      `,
      styles.getAdjustedHeight(theme),
    ]}
  />
);

Overlay.displayName = 'Overlay';

type DrawerOverlayProps = {
  isOpen?: boolean;
  keepMounted: boolean;
  children: React.ReactNode;
  zIndex?: number;
};

const DrawerOverlay = ({ isOpen, keepMounted, children, zIndex }: DrawerOverlayProps) => {
  if (keepMounted) {
    return (
      <>
        <AnimatePresence>{isOpen && <Overlay zIndex={zIndex} />}</AnimatePresence>
        {children}
      </>
    );
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <Overlay zIndex={zIndex} />
          {children}
        </>
      )}
    </AnimatePresence>
  );
};

export const DrawerBase = forwardRef<HTMLDivElement, DrawerBaseProps>(
  (
    {
      labelId,
      descriptionId,
      close,
      side = 'right',
      size = 'small',
      isOpen,
      keepMounted = false,
      context,
      children,
      ...rest
    },
    ref
  ) => {
    const variants = useMemo(() => createDrawerVariants(keepMounted)[side], [keepMounted, side]);

    return (
      <DrawerOverlay zIndex={rest?.zIndex} isOpen={isOpen} keepMounted={keepMounted}>
        <FloatingFocusManager context={context}>
          <DrawerContext.Provider value={{ labelId, descriptionId, close }}>
            <DrawerPanel
              ref={ref}
              labelId={labelId}
              descriptionId={descriptionId}
              isOpen={isOpen}
              side={side}
              size={size}
              variants={variants}
              {...rest}
            >
              {children}
            </DrawerPanel>
          </DrawerContext.Provider>
        </FloatingFocusManager>
      </DrawerOverlay>
    );
  }
);

DrawerBase.displayName = 'DrawerBase';

interface DrawerProps extends DrawerBaseProps {
  nodeId: string;
  rootId?: string;
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
}

export const DrawerOptional = forwardRef<HTMLDivElement, DrawerProps>(
  ({ nodeId, rootId, root, isOpen, context, ...props }, ref) => {
    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal id={rootId} root={root}>
          <DrawerBase ref={ref} isOpen={isOpen} context={context} {...props} />
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

DrawerOptional.displayName = 'DrawerOptional';

const DrawerNamespace = Object.assign(DrawerOptional, {
  Header: DrawerHeader,
  Content: DrawerContent,
});

export { DrawerNamespace as Drawer };
