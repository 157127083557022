import { css } from '@emotion/react';
import {
  popoverMenuItemTheme,
  popoverMenuItemThemeOriginal,
  popoverMenuTheme,
  popoverMenuThemeOriginal,
  PopoverMenuThemeValues,
  PopoverMenuItemThemeValues,
} from './popover-menu-theme';

type LiStyleProps = { disabled?: boolean; active?: boolean; destructive?: boolean };

const menuDividerStyle = ({
  color,
  dividerColor,
  spacing,
}: { color?: PopoverMenuItemThemeValues['dividerColor'] } & PopoverMenuItemThemeValues) => css`
  margin-top: ${spacing(0.5)};
  margin-bottom: ${spacing(0.5)};
  border-top: 1px solid ${color || dividerColor};
`;

const menuItemStyle = (
  { backgroundColor, disabledColor, focusedBorderColor, spacing }: PopoverMenuItemThemeValues,
  props?: LiStyleProps
) => [
  css`
    align-items: center;
    background: none;
    border: none;
    cursor: default;
    display: flex;
    height: 40px;
    min-height: 40px;
    justify-content: flex-start;
    margin: 0;
    outline: none;
    padding: ${spacing(0, 2)};
    gap: ${spacing(1)};
    position: relative;
    text-decoration: none;
    transition: background-color 250ms ease-out;

    > :first-letter {
      text-transform: uppercase;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      display: inline;
      text-align: start;
    }

    :hover {
      background-color: ${backgroundColor};
    }
    :focus {
      background-color: ${backgroundColor};
      outline: none;
    }
  `,

  !props?.destructive &&
    css`
      svg {
        /* fill needs to be here in case the svg is inside a :visited <a> tag */
        fill: inherit;
      }
    `,
  props?.disabled &&
    css`
      color: black;
      cursor: default;
      pointer-events: none;
      :hover,
      :focus {
        outline: none;
      }
      span {
        color: ${disabledColor};
      }
      ,
      svg {
        fill: ${disabledColor};
        color: ${disabledColor};
      }
    `,
  props?.active &&
    css`
      background: ${backgroundColor};
      outline: none;
      ::before {
        content: '';
        background: ${focusedBorderColor};
        height: 100%;
        width: 3px;
        left: 0;
        position: absolute;
      }
    `,
];

const menuItemDropdownStyle = (theme: PopoverMenuItemThemeValues, props?: LiStyleProps) => {
  return css([
    menuItemStyle(theme, props),
    css`
      flex-shrink: 0;
      column-gap: ${theme.spacing(1)};
    `,
  ]);
};

const menuStyle = ({ spacing, borderRadius, shadows, fontSize, zIndex }: PopoverMenuThemeValues) => css`
  background: white;
  border-radius: ${borderRadius};
  box-shadow: ${shadows};
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  min-width: 100px;
  overflow: auto;
  padding: ${spacing(1, 0)};
  z-index: ${zIndex};

  :focus {
    outline: none;
  }

  h2 {
    border-left: 2px solid transparent;
    font-size: ${fontSize(18)};
    margin: 0;
    padding: ${spacing(1, 2)};
    text-transform: capitalize;
  }
`;

export const PopoverMenuStyles = {
  PopoverMenu: {
    menuItem: (props: LiStyleProps) => menuItemStyle(popoverMenuItemTheme, props),
    menuItemDropdown: (props: LiStyleProps) => menuItemDropdownStyle(popoverMenuItemTheme, props),
    menu: () => menuStyle(popoverMenuTheme),
    divider: ({ color }: { color?: PopoverMenuItemThemeValues['dividerColor'] }) =>
      menuDividerStyle({ color, ...popoverMenuItemTheme }),
  },
};

export const PopoverMenuStylesOriginal: typeof PopoverMenuStyles = {
  PopoverMenu: {
    menuItem: (props: LiStyleProps) => menuItemStyle(popoverMenuItemThemeOriginal, props),
    menuItemDropdown: (props: LiStyleProps) => menuItemDropdownStyle(popoverMenuItemThemeOriginal, props),
    menu: () => menuStyle(popoverMenuThemeOriginal),
    divider: ({ color }: { color?: PopoverMenuItemThemeValues['dividerColor'] }) =>
      menuDividerStyle({ color, ...popoverMenuItemThemeOriginal }),
  },
};
